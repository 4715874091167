import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import { TimePicker, notification } from 'antd';
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import { dProgramModalAction, programDatesAction } from '../../store/ui';
import { addBr, colorsList, getBr, getCroppedImg, getHtml, getprogramdatesinCart } from '../../helper';
import { updateProgramImg, editProgram, updateAdditionalExp, programDelete, deleteGalleryImgService } from '../../services/directorServices';
import { getGalleryService, uploadGalleryService, cloneProgramService, copyProgramItemsService } from '../../services/programService';
import { childAction } from '../../store/program';
import { CustomTextarea, InputError, CustomNumber, CustomSelect } from '../reuse/FormControls';
import { LogoWhite } from '../reuse/SVG';


const isColor = c => {
    let s = new Option().style;
    s.color = c;
    return s.color === c;
  }

const CampCard = props => {
    const [progMediaModal, setProgMediaModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [images, setImages] = useState(props.CampDetails.programImages);
    const [loader, setLoader] = useState(false);
    const [packLoader, setPackLoader] = useState(false);
    const [uploadPhotoModal, setUploadPhotoModal] = useState(false);
    const [cImage, setCImage] = useState('');
    const [upImg, setUpImg] = useState();
    const [uploadImage, setUploadImage] = useState('');
    const imgRef = useRef(null);
    const mediaRef = useRef(null);
    const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 9 });
    const [scheduleModal, setScheduleModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentIndex, setCurrentndex] = useState('');
    const [time, setTime] = useState('');
    const [activity, setActivity] = useState('');
    const [timeError, setTimeError] = useState('');
    const [activityError, setActivityError] = useState('');
    const [list, setList] = useState(props.CampDetails.schedule);
    const [deleteModal, setDeleteModal] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [experienceModal, setExperienceModal] = useState(false);
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [titleError, setTitleError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [aEList, setAEList] = useState(props.CampDetails.additionalExp ? props.CampDetails.additionalExp : []);
    const [additionalDates, setAdditionalDates] = useState(props.CampDetails.additionalDates ? props.CampDetails.additionalDates : []);
    const [byDates, setByDates] = useState(props.CampDetails.byDates ? props.CampDetails.byDates : 2);

    const [cloneProgramModal, setCloneProgramModal] = useState(false);
    const [cloneDataName, setCloneDataName] = useState('');
    const [selectProgramModal, setSelectProgramModal] = useState(false);
    const [selectProgramList, setSelectProgramList] = useState([]);
    const [programsId, setProgramsId] = useState([]);
    const [selectAllBtn, setSelectAllBtn] = useState(false);
    const [btnLoadeer, setBtnLoadeer] = useState(false);
    const [color, setColor] = useState('');
    const [colorErr, setColorErr] = useState('');
    const [additionalExpEditItiem, setAdditionalExpEditItiem] = useState('');
    const [maxAdditionalExpCount, setMaxAdditionalExpCount] = useState('');
    const [remainingAdditionalExpCount, setRemainingAdditionalExpCount] = useState('');
    const [remainingAdditionalExpCountTemp, setRemainingAdditionalExpCountTemp] = useState('');
    const [maxAdditionalExpCountErr, setMaxAdditionalExpCountErr] = useState('');
    const [viewType, setViewType] = useState('');


    const {
        _id,
        programName,
        availableSlots,
        programCode,
        startDate,
        endDate,
        checkInTime,
        checkOutTime,
        status,
        noOfBooking,
        isCabin,
        programImages,
        additionalExp,
        schedule,
        pdf,
        isOnline
    } = props.CampDetails;


    useEffect(() => {
        if(viewType === 1){
          setCrop({ unit: "%", width: 100, aspect: 1 / 1 })
        }else{
          setCrop({ unit: "%", width: 100, aspect: 16 / 9 })
        }
    
      }, [viewType])




    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          setCImage(e.target.files)
          const reader = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
    };
    
    
    const onLoad = useCallback(img => {
        imgRef.current = img;
        //mediaRef.current.value = '';
    }, []);



    const completedCrop = crop => {
        getCroppedImg(imgRef.current, crop, cImage[0].name, cImage[0].type).then(res => setUploadImage(res))
    }



    const handleFileUpload = () => {
        if(upImg){
          setLoader(true);
          let params = new FormData();
              params.append('programId', _id);
              params.append('programImage', uploadImage);
              params.append('viewType', viewType);
    
          updateProgramImg(params).then(res => {
            setViewType('');
            if(res?.data?.statusCode){
              setLoader('');
              setCImage('');
              setUpImg('');
              setImages(res.data.responseData.result);
            }
          })
        }
    }



    const handleRemove = urlId => {
        setLoader(true);
        let params = new FormData();
            params.append('programId', _id);
            params.append('urlId', urlId);
    
            updateProgramImg(params).then(res => {
              setLoader(false);
              if(res?.data?.statusCode){
                setImages(res.data.responseData.result)
              }
            })
    }
    


    const closeModal = () => {
        setProgMediaModal(false);
        setScheduleModal(false);
        setExperienceModal(false);
        setDeleteModal(false);
        setUploadPhotoModal(false);
        setSelectProgramModal(false);
        setProgramsId([]);

        let tempAEList = aEList.filter(item => item._id)
        setAEList(tempAEList);
        setTitle('');
        setPrice('');
        setColor('');
        setDescription('');
        setMaxAdditionalExpCount('');
        setRemainingAdditionalExpCount('');
    }


    const handleFileChange = e => {
        setPackLoader(true);
        let file = e.target.files[0];
        let params = new FormData();
                params.append('programId', _id);
                params.append('pdf', file);

        editProgram(params).then(res => {
            setPackLoader(false);
            if(res?.data?.statusCode){
                notification.open({
                    message: 'Program',
                    description: `Program updated successfully.`
                });
            }
        })
      }




    const handleValidate = () => {
        let validate = true;
            if(time === ''){
                setTimeError('time is required');
                validate = false
            }

            if(activity === ''){
                setActivityError('description is required');
                validate = false
            }
         
            // let timesArr = list.map(item => item.time);
            // if(timesArr.includes(time)){
            //     setTimeError('Do not enter same time schedule');
            //     validate = false
            // }

        return validate;

    }



    const handleAdd = () => {
        if(handleValidate()){
            setList([...list, {time, activity }]);
            setTimeError('');
            setActivityError('');
            setActivity('');
            setTime('');
        }   
    }
    




    const handleSubmit = e => {
        e.preventDefault();

        if(list.length){
            let params = new FormData();
            params.append('programId', _id);
            params.append('schedule', JSON.stringify(list));
            params.append('isOnline', isOnline);

            editProgram(params).then(res => {
                closeModal();
                if(res?.data?.statusCode){
                    props.childAction();
                    notification.open({
                        message: 'Program',
                        description: `Program updated successfully.`
                    });
                }
            })
        }

        
    }
    

    const handleDelete = index => {
        let tempList = [...list];
        tempList.splice(index, 1);
        setList(tempList);
    }


    const handleDeleteAEList = index => {
        let tempList = [...aEList];
        tempList.splice(index, 1);
        setAEList(tempList);
    }


    const handleEditAEList = item => {
        setAdditionalExpEditItiem(item);
        setTitle(getBr(item.title));
        setPrice(item.price);
        setDescription(getBr(item.description));
        setColor(item.color);
        setMaxAdditionalExpCount(item.maxAdditionalExpCount);
        setRemainingAdditionalExpCount(item.remainingAdditionalExpCount);
        setRemainingAdditionalExpCountTemp(item.maxAdditionalExpCount - item.remainingAdditionalExpCount);
    }


    const handleEdit = index => {
        setCurrentndex(index);
        setIsEditMode(true)
        let tempData = list[index];
        setActivity(tempData.activity);
        setTime(tempData.time);
        let el = document.getElementById('scrollIntoView');
        el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }


    const handleEditApi = () => {
        let tempList = [...list];
        tempList.splice(currentIndex, 1);

        let params = new FormData();
            params.append('programId', _id);
            params.append('schedule', JSON.stringify([...tempList, { time, activity }]));
            params.append('isOnline', isOnline);

            editProgram(params).then(res => {
                setIsEditMode(false);
                if(res?.data?.statusCode){
                    let _list = [...list];
                    _list[currentIndex].activity = activity;
                    _list[currentIndex].time = time;
                    setList(_list);
                    setIsEditMode(false);
                    setActivity('');
                    setTime('')
                    notification.open({
                        message: 'Program',
                        description: `Program updated successfully.`
                    });
                }
            })
    }



    const handleValidate2 = () => {
        let validate = true;

        if(title.trim() === ''){
            setTitleError('Title is required');
            validate = false
        }

        if(price === 'undefined' || price === null || price === ''){
            setPriceError('Price is required');
            validate = false
        }

        if(maxAdditionalExpCount === 'undefined' || maxAdditionalExpCount === null || maxAdditionalExpCount === ''){
            setMaxAdditionalExpCountErr('Total slots is required');
            validate = false
        }

        if(description.trim() === ''){
            setDescriptionError('description is required');
            validate = false
        }

        if(color !== '' || color !== undefined || color !== 'undefined'){
            if(!isColor(color)){
                setColorErr('Enter valid color');
                validate = false
            }
        }
            
        return validate;

    }


    const handleExpCountChange = e => {
        setMaxAdditionalExpCountErr('')
        if(remainingAdditionalExpCountTemp){
            if(e < remainingAdditionalExpCountTemp){
                setMaxAdditionalExpCountErr('Please enter value greater then available slots') 
            }else{
                setMaxAdditionalExpCount(e);
                setRemainingAdditionalExpCount(e - remainingAdditionalExpCountTemp);
            }
        }else{
            setMaxAdditionalExpCount(e);
            setRemainingAdditionalExpCount(e);
        }
        
       
    }




    const handleAdd2 = () => {
        if(handleValidate2()){
            if(additionalExpEditItiem === ''){
                setAEList([...aEList, {
                        price,
                        description: addBr(description),
                        title: addBr(title),
                        color,
                        maxAdditionalExpCount,
                        remainingAdditionalExpCount,
                    }
                ]);
            }else{
                let tempList = [...aEList];
                let index;
                tempList.findIndex((item, i) => {
                    if (item._id === additionalExpEditItiem._id) {
                        index = i;
                        return true;
                    }
                    return null;
                });
                tempList.splice(index, 1, {_id: additionalExpEditItiem._id, price, description: addBr(description), title: addBr(title), color, maxAdditionalExpCount, remainingAdditionalExpCount });
                setAEList(tempList);
            }
            

            setTitle('');
            setTitleError('');
            setPrice('');
            setPriceError('');
            setDescription('');
            setDescriptionError('');
            setColor('');
            setAdditionalExpEditItiem('');
            setMaxAdditionalExpCount('');
            setRemainingAdditionalExpCount('');
        }   
    }


    const handleSubmit2 = e => {
        e.preventDefault();

        if(list.length){
            let params = {
                programId: _id,
                additionalExp: JSON.stringify(aEList)
            }

            setBtnLoadeer(true)
            
            updateAdditionalExp(params).then(res => {
                closeModal();
                setBtnLoadeer(false)
                if(res?.data?.statusCode){
                    props.childAction();
                    notification.open({
                        message: 'Program',
                        description: `Program updated successfully.`
                    });
                }
            })
        }

        
    }




    const handleProgramDelete = () => {
        let params = { programId: _id };
        programDelete(params).then(res => {
            closeModal();
            if(res?.data?.statusCode){
                props.childAction();
                notification.open({
                    message: 'Program',
                    description: `Program delete successfully.`
                });
            }
        }); 
    }



    const getPhotos = () => {
        let query = `?programId=${_id}&pageNo=${1}&limit=${10000}`;

        getGalleryService(query).then(res => {
            if(res?.data?.statusCode){
                setPhotos(res.data.responseData.gallery)
            }
        })
    }


    useEffect(() => {
        if(uploadPhotoModal){
            getPhotos();
        }
    }, [uploadPhotoModal])



    const handlePhotoUpload = e => {
        let file = e.target.files[0];

        let params = new FormData();
            params.append('programId', _id);
            params.append('gallery', file);

        uploadGalleryService(params).then(res => {
            notification.open({
                description: 'Photo uploaded successfully',
            });
            getPhotos();
            
        })
    }


    const handleCabinClick = () => {
        if(noOfBooking){
            if(props.userProfile.UserType === 4){
                setWarningModal(true);
            }else{
                props.setCampersInCabin()
            }
              
        }else {
            setWarningModal(true);
        }
        
    }


    


    const handleClone = () => {
        let programCode = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 7; i++){
            programCode += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        let params = {
            programId: _id,
            programCode
        }

        setCloneProgramModal(false);
        cloneProgramService(params).then(res => {
            
            if(res?.data?.statusCode){
                props.childAction();
                notification.open({
                    message: 'Program',
                    description: `Program clone successfull.`
                });
            }
        })
    }




    const cloneProgramData = name => {
        setCloneDataName(name);
        setSelectProgramModal(true);
        let tempList = props.sPrograms.filter(item => _id !== item._id);

        setSelectProgramList(tempList);
            

    }


 



    const handleMultiCheck = (e, id) => {
        if(programsId.includes(id)){
            let temp = [...programsId];
            const index = temp.indexOf(id);
            if (index > -1) {
                temp.splice(index, 1);
            }

            setProgramsId(temp);

        }else{
            setProgramsId([...programsId, id])
        }
    }



    const submitCloneProgram = () => {
       
        let params = {
            programIds: JSON.stringify(programsId),
        }

        if(cloneDataName === 'programImages'){
            params.programImage = JSON.stringify(programImages)
        }
        if(cloneDataName === 'schedule'){
            params.schedule = JSON.stringify(schedule)
        }
        if(cloneDataName === 'additionalExp'){
            params.additionalExp = JSON.stringify(additionalExp)
        }
        if(cloneDataName === 'pdf'){
            params.pdf = JSON.stringify(pdf)
        }


        copyProgramItemsService(params).then(res => {
            if(res?.data?.statusCode){
                closeModal();
                props.childAction();
            }else{
                closeModal(); 
            }
        })  
    }


    const handleSelectAndDeselect = e => {
        setSelectAllBtn(e.target.checked)
        if(e.target.checked){
            let tempIds = selectProgramList.map(item => item._id);
            setProgramsId(tempIds);
        }else{
            setProgramsId([]);
        }
    }



    const handleDeleteGalleryImg = imageUrl => {
        let params = {imageUrl, programId: _id}
        let temp = photos.filter(e => e !== imageUrl);
        setPhotos(temp);
        deleteGalleryImgService(params);
    }

/** *
    const getSelectedDates = (_all) => {
        return _all.map(i => (
        <span className='cm_selected_date' key={i._id}>
            {getprogramdatesinCart(i.startDate,i.endDate)}
        </span>
        ));
    }
/** */


    const handleAddQuestion = index => {
        let _aEList = [...aEList];
        _aEList[index].question = {
            questionType: '',
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
        }
        setAEList(_aEList);

    }

    const handleQuestionTypeChange = (e, index) => {
        let _aEList = [...aEList];
        _aEList[index].question = {
            questionType: e.value,
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
        }
        setAEList(_aEList);
    }


    const handleIsOtherOptionChnage = index => {
        let _aEList = [...aEList];
        _aEList[index].question.isOtherOption = _aEList[index]?.question?.isOtherOption  === 1 ? 2 : 1;
        setAEList(_aEList);  
    }
    


    const handleQuestionChange = (e, index) => {
        let _aEList = [...aEList];
        _aEList[index].question.title = e.target.value;
        setAEList(_aEList); 
        
    }
    const handleQuestionSubTitleChange = (e, index) => {
        let _aEList = [...aEList];
        _aEList[index].question.subTitle = e.target.value;
        setAEList(_aEList);
        
    }

    const getQuestionType = i => {
        return questionTypes[i - 1]
    }


    const handleClearValue = ind => {
        let _aEList = [...aEList];
        _aEList[ind].question.optionVal = '';
        _aEList[ind].question.optionValErr = '';
        setAEList(_aEList);
    }


    const handleAddOption = ind => {

        let _aEList = [...aEList];
    
        if(_aEList[ind].question.optionVal && _aEList[ind].question.optionVal.trim()){
    
            let isDuplicate = _aEList[ind].question.options.filter(i => i.opt === _aEList[ind].question.optionVal);

            if(isDuplicate.length){
                _aEList[ind].question.optionValErr = 'dupliate value not allowed';
                setAEList(_aEList);
            }else{
                let temp = [..._aEList[ind].question.options];
                temp.push({opt: _aEList[ind].question.optionVal});
                _aEList[ind].question.options = temp;
                setAEList(_aEList);
                handleClearValue(ind)
            }        
    
        }
    }
    
    const handleOptionValChange = (e, ind) => {
        let _aEList = [...aEList];
        _aEList[ind].question.optionVal = e.target.value;
        _aEList[ind].question.optionValErr = '';
        setAEList(_aEList);
    
    }


    const handleRemoveOption = (index, childIndex) => {
        let _aEList = [...aEList];

        let temp = [..._aEList[index].question.options];

        temp.splice(childIndex, 1);

        _aEList[index].question.options = temp;

        setAEList(_aEList);
    }


    const questionTypes = [
        {label: 'Short Answer', value: 1},
        {label: 'Drop-down', value: 2},
        // {label: 'Multiple Choice', value: 3},
        {label: 'Checkboxes', value: 4},
    ];


    const handleIsRequredChnage = i => {
        let _aEList = [...aEList];
        _aEList[i].question.isRequred = _aEList[i].question.isRequred === 1 ? 2 : 1
        setAEList(_aEList);    
    }



    return(
        <div className="d-flex align-items-center justify-content-between dir_camp_card">

            <Modal
                show={cloneProgramModal}
                centered
                dialogClassName="remove_header_line">

                <Modal.Header>
                <span className="modal_close" onClick={() => setCloneProgramModal(false)}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">Are you sure you want to Clone this program?</p>
                    <div className="d-flex justify-content-center mt-5 mb-4">
                        <button onClick={() => setCloneProgramModal(false)} className="btn btn-light mr-4 pl-5 pr-5">No</button>
                        <button className="btn btn-primary pl-5 pr-5" onClick={handleClone}>Yes</button>
                    </div>
                </Modal.Body>
            </Modal>





            <Modal show={selectProgramModal} centered dialogClassName="remove_header_line">
                <Modal.Header>
                <Modal.Title>Select Program</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="select_all">
                        <div className="selct_program">
                            <div className="check_wrapper table_check"><input onChange={handleSelectAndDeselect} type="checkbox" /><span /></div> <b>{selectAllBtn ? 'Deselect' : 'Select'}  All</b>
                        </div>
                    </div>
                        {
                        selectProgramList.map(item => <div className="selct_program">
                            <div className="check_wrapper table_check"><input onChange={e => handleMultiCheck(e, item._id)} checked={programsId.includes(item._id) ? true : false} type="checkbox" /><span /></div> {item.programName}
                        </div>)
                        }
                    {programsId.length ? <div className="text-center">
                        <span onClick={submitCloneProgram} className="btn btn-primary">Apply</span>
                    </div> : ''}
                    
                </Modal.Body>
            </Modal>




        <div className="d-flex align-items-center">
          <Link to={`/director/camp/${_id}`} className="d-flex align-items-center">
            <div className="">
                {
                    programImages.length > 0 ? 
                    <img src={programImages[0].url} />    
                    :
                    <div className="no_img_2"><LogoWhite /></div>
                }
            </div> 
            <div className="">
                <h3>{programName}</h3>
                <p>Program Code: {programCode}</p>
                <p>Date : {moment.utc(startDate).format('DD MMMM, YYYY')} - {moment.utc(endDate).format('DD MMMM, YYYY')} </p>
                <p>Timing : {`${checkInTime} - ${checkOutTime}` }</p>
            </div>
           </Link>  
        </div> 


        <div className="d-flex align-items-center cm_mob_center">
            {/* <div className="cm_slots">
                <p>Grouped Participants</p>
                <h4>5</h4>
            </div> */}
            <div className="cm_slots">
                <p>Participants</p>
                <h4>{noOfBooking}</h4>
            </div>
            <div className="cm_slots cm_slots_2">

                
            {byDates === 1 ? (
                    <div className='participant_count'>
                    {additionalDates.slice(0, 3).map(item => (
                        <Fragment>
                            <span className={`cm_selected_date`} key={item._id}>
                                {getprogramdatesinCart(item.startDate, item.endDate)}
                                <b title='Number of Slots'>{item.slots}</b>
                            </span>
                            <br />
                        </Fragment>
                    ))}

                    {additionalDates.length > 3 ? <span className='my_view_all' onClick={() => props.programDatesAction(additionalDates)}>View All Dates</span> : ''}
                </div>
                ) : (
                    props.campListType !==2 ?   
                <Fragment>
                    <p>Available Slots</p>
                    <h4>{availableSlots}</h4>
                </Fragment> : ''
                 
                )}
            </div>

           
        </div> 


        <div className="d-flex align-items-center position-relative">

            {
                props.userProfile.UserType === 4 ? '' : <div className="mr-3 cm_mob_swich">
                <div className="cm_swich_wrap">
                   {
                       props.campListType!==2 &&
                       <>
                       <input type="checkbox"  checked={status===1 ? 'checked' : ''} onChange={()=>{props.setProgramStatus(_id, status, props.ProgramIndex)}}/>
                       <span />
                       </>
                   }

                </div>
            </div> 
            }

            <div className="hide_on_print">
                <Link className="btn btn-dark btn-sm cm_online_store_btn" to={`/online-store/${_id}`}>Products</Link>
            </div>
            
            <div className="hide_on_print">
                {props.campListType !==2 ? <Fragment>
                    {isCabin === 1 ? <Link className="btn btn-primary" to={`/cabin-organizer/${_id}`}>Group Organizer</Link> : <span className="btn btn-primary" onClick={handleCabinClick}>Group Organizer</span>}
                </Fragment>
                 :
                <button className="btn btn-primary" onClick={() => setUploadPhotoModal(true)}>Upload Photos</button>
            }
                
                
            </div> 
        </div> 

        {(props?.userProfile?.UserType === 4 || props?.userProfile?.UserType === 2) ? props?.userProfile?.UserType === 4 && !props.editProgram ? '' : props.campListType !== 2 ? (
            <div className="all_actions hide_on_print">

            <i  
                onClick={() => setCloneProgramModal(true)}
                title="Clone Program"
                style={{color: '#842014'}}
                className="icon-copy" />

            <span className="clone_icons">
                <span className="program_pdf" title="Upload Packing List">
                    {packLoader ? <span className='pack_loader' /> : ''}
                    <i title="" style={{color: '#2B2B2B'}} className="icon-add-doc" />
                    <input accept=".pdf" type="file" onChange={handleFileChange} />
                </span>
                
                {pdf ? <i title="Clone Upload Packing List" className="icon-copy clone_icon" onClick={() => cloneProgramData('pdf')}  /> : ''}
            </span>

            <span className="clone_icons">
                <i title="Add / Remove Media" style={{color: '#2B2B2B'}} className="icon-add-media" onClick={() => setProgMediaModal(true)} />
                {images.length ? <i title="Clone Media" className="icon-copy clone_icon" onClick={() => cloneProgramData('programImages')} /> : ''}
                
                
            </span>

            <span className="clone_icons">
                <i title="Add / Remove Schedule" style={{color: '#2B2B2B'}} className="icon-add-date" onClick={() => setScheduleModal(true)} />
                <i title="Clone Schedule" className="icon-copy clone_icon" onClick={() => cloneProgramData('schedule')} />
            </span>

            <span className="clone_icons">
                <i title="Add Additional Experience" style={{color: '#2B2B2B'}} className="icon-smile" onClick={() => setExperienceModal(true)} />
                {additionalExp?.length ? <i title="Clone Additional Experience" className="icon-copy clone_icon" onClick={() => cloneProgramData('additionalExp')} /> : ''}
            </span>


            <i title="Delete Program" style={{color: '#2B2B2B'}} className="icon-delete" onClick={() => setDeleteModal(true)} />

            <i className="icon-pancil" style={{color: '#842014'}} onClick={() => props.dProgramModalAction(_id)} />

            </div>
        ) : '' : ''}

        
        
        









        <Modal
            size="lg"
            show={progMediaModal}
            animation={false}
            dialogClassName="remove_header_line"
            centered >

            <Modal.Header>
            <Modal.Title>Program Media</Modal.Title>
            <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
                <div className="text-center">
                    <div className="icon_upload_wrapper">
                        <input ref={mediaRef} type="file" onChange={onSelectFile} accept="image/x-png, image/jpeg, image/webp, image/avif" />
                        <div><i className="icon-button" /><p>Upload Image</p></div>
                    </div>

                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={c => setCrop(c)}
                        onComplete={completedCrop} />

                        {upImg ? <div className='d-flex justify-content-between align-items-center cm_questions my-4'>
                        <p className='m-0'>Is For Mobile View ?</p>
                        <div className="cm_swich_wrap">
                            <input type="checkbox"  checked={viewType === 1 ? true : false} onChange={e => setViewType(viewType === 1 ? '' : 1)} />
                            <span />
                        </div>
                        </div> : ''}


                        


                    <div className="text-center">
                        {upImg ? <span onClick={loader ? null : handleFileUpload} style={{paddingLeft: '50px', paddingRight: '50px'}} className={`btn btn-primary d-inline rounded-0 btn-block mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Save'}</span> : ''}
                    </div>
                
                    <ul className="mt-4 mb-4 text-center program_imgs">
                        {images.filter(i => !i.viewType).map(item => (
                                <li key={item._id}><img src={item.url} /> <i className="icon-cross" onClick={() => handleRemove(item._id)} /></li>
                            ))}
                    </ul>


                    <ul className="mt-4 mb-4 text-center program_imgs program_imgs_for_mob">
                        {images.filter(i => i.viewType === 1).map(item => (
                                <li key={item._id}><img src={item.url} /> <i className="icon-cross" onClick={() => handleRemove(item._id)} /></li>
                            ))}
                    </ul>





                    
                </div>
            </Modal.Body>
        </Modal>








        <Modal
            size="lg"
            show={scheduleModal}
            animation={false}
            dialogClassName="remove_header_line"
            centered >

            <Modal.Header>
                <Modal.Title>Add Schedule</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
            {
                list ? list.map((item, index) => (
                    <div className="schedule_item" key={index}>
                        <p>Time: {item.time}</p>
                        <p>Description: {item.activity}</p>

                        <div className="cm_action">
                            <i className="icon-pancil" onClick={() => handleEdit(index)} />
                            <i className="icon-delete" onClick={() => handleDelete(index)} />
                        </div>
                    </div>
                )) : ''
            }

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Time</label>
                        <TimePicker
                            use12Hours
                            format="h:mm a"
                            value={time ? moment(time, 'h:mm a') : ''}
                            onChange={e => (setTime(moment(e._d).format('h:mm a')), setTimeError(''))}
                            className={`form-control ${timeError ? 'is-invalid' : ''}`} />
                            <InputError error={timeError} />
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <CustomTextarea
                            pHolder="Write Description here…"
                            value={activity}
                            error={activityError}
                            change={e => (setActivity(e.target.value), setActivityError(''))}/>
                    </div>

                    {
                        isEditMode ? '' : <div className="text-right">
                                            <span onClick={handleAdd}  className="btn pl-0 pr-0"><i className="icon-plus" /> Add</span>
                                        </div>
                    }

                    

                    <div id="scrollIntoView" className="d-flex justify-content-center mt-4 mb-4">
                        {isEditMode ? <span className="btn btn-primary" onClick={handleEditApi}>Edit</span> : <button type="submit" disabled={list && list.length ? false : true} className="btn btn-primary">Save</button>}
                        
                    </div>
                </form>
            </Modal.Body>
        </Modal>









        <Modal
            size="lg"
            show={experienceModal}
            dialogClassName="remove_header_line"
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Additional Experience</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>

            {
                aEList ? aEList.map((item, index) => (
                    <div className="schedule_item" key={index}>
                        <h6 style={{color: item.color}} className="mb-2"><b>Title:</b> {getHtml(item.title)}</h6>
                        <p style={{color: item.color}} className="mb-2"><b>Price:</b> ${item.price.toFixed(2)}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Totoal Slots:</b> {item.maxAdditionalExpCount}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Available Slots:</b> {item.remainingAdditionalExpCount}</p>
                        <p style={{color: item.color}} className="mb-2"><b>Description:</b> {getHtml(item.description)}</p>
                        <div className="cm_action">
                            <i className="icon-delete" onClick={() => handleDeleteAEList(index)} />
                            {item._id ? <i className="icon-pancil" onClick={() => handleEditAEList(item)} /> : ''}
                        </div>


                        {
                            item.question ? <div className="form-group cm_questions cm_questions2">
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <label>Question</label>
                                                    <span className='question_types mr-0'>
                                                    {(item.question.questionType === 3 || item.question.questionType === 4) ? <Fragment>
                                                        <p>Is Other Option ?</p>
                                                        <div className="cm_swich_wrap">
                                                            <input onChange={() => handleIsOtherOptionChnage(index)} type="checkbox"  checked={item.question.isOtherOption === 1 ? true : false} />
                                                            <span />
                                                        </div>
                                                    </Fragment> : ''}
                                                    

                                                    <p>Is Mandatory ?</p>
                                                    <div className="cm_swich_wrap">
                                                        <input type="checkbox"  checked={item.question.isRequred === 1 ? true : false} onChange={e => handleIsRequredChnage(index)} />
                                                        <span />
                                                    </div>
                                                    <CustomSelect
                                                        pHolder="Select Question Type"
                                                        value={getQuestionType(item.question.questionType)}
                                                        onChange={e => handleQuestionTypeChange(e, index)}
                                                        options={questionTypes} />
                                                    </span>
                                                </div>



                                                {
                                                    item.question.questionType ? <div className='cm_ques'>
                                                    <CustomTextarea
                                                        pHolder="Question*"
                                                        value={item.question.title}
                                                        change={e => handleQuestionChange(e, index)} />
                                                    
                                                    <CustomTextarea
                                                        pHolder="Sub Title"
                                                        value={item.question.subTitle}
                                                        change={e => handleQuestionSubTitleChange(e, index)} />

                                                </div> : ''
                                                }


                                        {(item.question.questionType === 2 || item.question.questionType === 3 || item.question.questionType === 4) ? <div className='cm_options'>
                                                {item.question.options.length ? <ul>{item.question.options.map((itm, j) => <li>{itm.opt} <i className="icon-close" onClick={() => handleRemoveOption(index, j)} /></li>)}</ul> : ''}
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${item.question.optionValErr ? 'is-invalid' : ''}`}
                                                        placeholder='Add Option'
                                                        value={item.question.optionVal}
                                                        onChange={e => handleOptionValChange(e, index)} />
                                                    
                                                    {item.question.optionValErr ? <h6>{item.question.optionValErr}</h6> : ''}
                                                    
                                                    <div>
                                                        <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(index)}><i className="icon-plus" /><small>Add</small></span>  
                                                    </div>
                                                </div>
                                            </div> : ''}
                            </div> : ''
                        }






                        {
                            item?.question ? '' : <div className="add_question_btn" onClick={() => handleAddQuestion(index)}><i className="icon-plus" /><small>Add Question</small></div>
                        }

                        
                    </div>
                )) : ''
            }


                <form onSubmit={handleSubmit2}>
                    <div className="form-group">
                        <label>Title</label>
                        <CustomTextarea
                            change={e => (setTitle(e.target.value), setTitleError(''))}
                            value={title}
                            pHolder="Title"
                            error={titleError}  />
                    </div>

                    {/* <div className="form-group">
                        <label>Price</label>
                        <CustomNumber
                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            change={e => (setPrice(e), setPriceError(''))}
                            value={price}
                            pHolder="Price"
                            error={priceError}  />
                    </div> */}



                    <div className="row">
                        <div className="col form-group">
                            <label>Price</label>
                            <CustomNumber
                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                change={e => (setPrice(e), setPriceError(''))}
                                value={price}
                                pHolder="Price"
                                min={0}
                                precision={2}
                                error={priceError}  />
                        </div>
                        {/* <div className="col form-group">
                            <label>Color</label>
                            <CustomText
                                type="color"
                                value={color}
                                error={colorErr}
                                change={e => (setColor(e.target.value), setColorErr(''))}
                                pHolder="Enter color" />
                        </div> */}


                        <div className="col form-group">
                            <label>Color</label>
                            <select
                                value={color}
                                className={`form-control ${colorErr ? 'is-invalid' : ''}`}
                                error={colorErr}
                                onChange={e => (setColor(e.target.value), setColorErr(''))}
                                placeholder="Select color">
                                    <option value="">Select color</option>
                                    {
                                        colorsList.map(colr => <option value={colr}>{colr}</option>)
                                    }
                                </select>
                        </div>
                        
                    </div>
                    <div className="row">
                    <div className="col form-group">
                        <label>Total Slots</label>
                        <CustomNumber
                                value={maxAdditionalExpCount}
                                error={maxAdditionalExpCountErr}
                                change={handleExpCountChange}
                                min={0}
                                precision={0}
                                step={1}
                                pHolder="Enter Additional Experience Count" />
                        
                    </div>

                    <div className="col form-group">
                        <label>Available Slots</label>
                        <CustomNumber
                                value={remainingAdditionalExpCount}
                                disabled/>
                        
                    </div>
                    </div>
                  
             
                    <div className="form-group">
                        <label>Description</label>
                        <CustomTextarea
                            value={description}
                            change={e => (setDescription(e.target.value), setDescriptionError(''))}
                            pHolder="Write Description here…"
                            error={descriptionError} />
                    </div>




                    <div className="text-right">
                        <span onClick={handleAdd2} className="btn pl-0 pr-0"><i className="icon-plus" /> Add</span>
                    </div>




                    <div id="scrollIntoView" className="d-flex justify-content-around mt-4 mb-4">
                        <span className="btn btn-gray" onClick={closeModal}>Cancel</span>
                        {btnLoadeer ? 
                            <span className="btn btn-primary">Loading...</span>
                            :
                            <button disabled={list.length ? false : true} className="btn btn-primary">Save</button>}
                        
                    </div>

                </form>
            </Modal.Body>
        </Modal>







        <Modal show={deleteModal} centered dialogClassName="remove_header_line">
            <Modal.Header>
            <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
                <p className="text-center">Are you sure you want to delete?</p>
                <div className="d-flex justify-content-center mt-5 mb-4">
                    <button onClick={closeModal} className="btn btn-light mr-4 pl-5 pr-5">No</button>
                    <button className="btn btn-primary pl-5 pr-5" onClick={handleProgramDelete}>Yes</button>
                </div>
            </Modal.Body>
        </Modal>












        <Modal
            size="lg"
            show={uploadPhotoModal}
            animation={false}
            centered
            dialogClassName="remove_header_line"
        >
            <Modal.Header>
            <Modal.Title>Upload Photos</Modal.Title>
            <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
            <div className="text-center">
                <div className="icon_upload_wrapper">
                <input type="file" onChange={handlePhotoUpload} accept="image/x-png, image/jpeg, image/webp, image/avif" />
                <div>
                    <i className="icon-button" />
                    <p>Upload Image</p>
                </div>
                </div>


            <ul className="mt-4 mb-4 text-center program_imgs">
                {    
                    photos.length ? photos.map(item => (
                        <li key={item}><img src={item} /><i className="icon-cross" onClick={() => handleDeleteGalleryImg(item)} /></li>
                    )) : ''
                }
            </ul>
            </div>

            

            </Modal.Body>
        </Modal>


        <Modal
            show={warningModal}
            animation={false}
            centered
            dialogClassName="remove_header_line"
        >
            <Modal.Header>
            <span className="modal_close" onClick={() => setWarningModal(false)}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
                <div className="p-5 text-center">
                    {props.userProfile.UserType === 4 ? 'permission denied' : 'Cabin can be organised after registrations dates are over.'}
                <h5></h5> 
                </div>
            </Modal.Body>
        </Modal>






    </div>
    )
}




const mapStateToProps = state => {
    let { sPrograms } = state.program;
    let { userProfile } = state.user
    return {
        sPrograms,
        userProfile
    };
  }
  
  const mapDispatchToProps = dispatch => ({
      dProgramModalAction: params => dispatch(dProgramModalAction(params)),
      childAction: () => dispatch(childAction()),
      programDatesAction: (params) => dispatch(programDatesAction(params)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CampCard);
