import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Slider from 'react-slick';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Rate, notification } from 'antd';
import { Col, Container, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LogoWhite, PDFIcon, ProgramLoader, UploadLoader } from '../reuse/SVG';
import { bookingDetailService, programFavourite, followcamps, bookingreview, updateBookingKeyService, cancelProgramBookingByCampers } from '../../services/programService';
import { uploadGardianPdfService } from '../../services/directorServices';
import { getProgramType, gradeArr2, getExperience, getFacility, campDates2, campDates, getHtml, getprogramdatesinCart, isNonEmptyArray, isLessThanEquals14Days } from '../../helper';
import Share from '../static/Share';
import { uploadMedia } from '../../services/campersServices';
import { v4 as uuidv4 } from 'uuid';
import setting from '../../config/settings';
import { Link } from 'react-router-dom';
import buttonGif from '../../assets/img/call-action-button.gif'
import PdfViewer from '../common/PdfViewer/PdfViewer';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const getSelectedDates = (_all, _sel) => {
    const _res = _all.filter(el => _sel.find((id) => el._id === id));
    return _res.map(i => (
    <span className='cm_selected_date' key={i._id}>
        {getprogramdatesinCart(i.startDate,i.endDate)}
    </span>
    ));
}

const getProgramPriceByDates = (allPrices, selectedDateByProgram) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }

    if(_totalPrice > 0){
        return `Price: $${_totalPrice.toFixed(2)}`
    }else{
        return 'Free'
    }
}



const getProgramPriceByDates2 = (allPrices, selectedDateByProgram) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }

    if(_totalPrice > 0){
        return _totalPrice.toFixed(2)
    }else{
        return 'Free'
    }
}




const MapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `180px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={4}
      defaultCenter={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}>
            <Marker
              position={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}
              icon={{
                url: require('../../assets/img/mapIcon.svg')
                }}
            >
            </Marker>
    </GoogleMap> 
  );

const ViewBooking = props => {

    let history = useHistory();
    const todayDate = new Date().getTime();

    const [bookingDetail, setBookingDetail]                                         = useState('');
    const [loader, setLoader]                                                       = useState(true);
    const [isFavourite, setIsFavourite]                                             = useState('');
    const [pdfLoader, setPdfLoader]                                                 = useState('');
    const [isFollowed, setIsFollowed]                                          = useState('');
    const [reviewText, setReviewText]                                               = useState('');
    const [reviewError, setReviewError]                                             = useState(false);
    const [reviewSuccess, setReviewSuccess]                                         = useState(false);
    const [rating, setRating]                                                       = useState(5);
    const [cancelModal, setCancelModal]                                             = useState(false);
    const [campers, setCampers]                                                     = useState([]);
    const [cancelBookingLoader, setCancelBookingLoader]                             = useState(false);
    const [cancellationAvailable, setCancellationAvailable]                         = useState('');
    const [cancellationType, setCancellationType]                                   = useState('');
    const [cancellationValue, setCancellationValue]                                 = useState('');
    const [refundAvailable, setRefundAvailable]                                     = useState('');
    const [refundType, setRefundType]                                               = useState('');
    const [refundOptions, setRefundOptions]                                         = useState([]);
    const [campName, setCampName]                                                   = useState([]);
    const [selectedCamperForCancelBooking, setSelectedCamperForCancelBooking]       = useState([]);
    const [activeForm, setActiveForm]       = useState({});
    const [isViewProducts, setIsViewProducts] = useState(false)
    const [activeProduct, setActiveProduct] = useState('')
    // const [prodcutsList, setProdcutsList]       = useState([]);
    const [disableDocsUpload, setDisableDocsUpload] = useState(false)


    const closeModal = () => {
        setCancelModal(false);
        setSelectedCamperForCancelBooking([]);
    }


    const getBookingInfo = () => {
        let params = {
            bookingId: props.match.params.id,
            programId: props.match.params.programId
        }
        bookingDetailService(params).then(res => {
            setPdfLoader('')
            if(res?.data?.statusCode){
                setBookingDetail(res.data.responseData.result);
                setIsFavourite(res.data.responseData.result.isFavourite);
                setIsFollowed(res.data.responseData.result.isFollowed);
                setCampers(res.data.responseData.result.campers);

                setCancellationAvailable(res.data.responseData.result.cancellationAvailable)
                setCancellationType(res.data.responseData.result.cancellationType)
                setCancellationValue(res.data.responseData.result.cancellationValue)
                setRefundAvailable(res.data.responseData.result.refundAvailable)
                setRefundType(res.data.responseData.result.refundType)
                setRefundOptions(res.data.responseData.result.refundOptions)
                setCampName(res.data.responseData.result.campName)


                setLoader(false);

            }else{
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        getBookingInfo();
    }, [])



    const updateBookingKey = () => {
        let params = {
            bookingId: props.match.params.id,
            programId: props.match.params.programId,
        }

        if(activeForm.type === 'keyDeposit'){
            params.keyDeposit = true;
            params.camperId = activeForm.camperId;
        }else if(activeForm.type === 'isDisclaimer'){
            params.isDisclaimer = true;
        }else{
            params.concussion = true;
            params.camperId = activeForm.camperId;
        }

        setActiveForm({})
        updateBookingKeyService(params).then(res => {
            if(res?.data?.statusCode){
                getBookingInfo();
            }
        })
    }



    const handleFavourite = () => {
        setIsFavourite(!isFavourite);
        let params = {
            programId: bookingDetail.programId,
            favourite: !isFavourite
        }
        programFavourite(params).then(res => {

        })
    }


    const handleFollow = () => {
        setIsFollowed(!isFollowed);
        let params = {
            campId: bookingDetail.campId,
            follow: !isFollowed
        }

        followcamps(params).then(res => {

        })
    }

    const submitReview = campId =>{
        if(reviewText.trim().length<=0){
            setReviewError(true)
        }else{
            let params = {
                "campId": campId,
                "programId": props.match.params.programId,
                "rating": ''+rating,
                "review": reviewText
            }
            bookingreview(params).then(res => {
                if(res?.data?.statusCode){
                    setReviewSuccess(true);
                    setReviewText('');
                }else{
                    notification.open({
                        description:
                          'Please try after some time',
                      });  
                }
            })
        }
    }



    const handleFileUpload = (e, camperId) => {
        setPdfLoader({[camperId]: true})
        let params = new FormData();
            params.append('bookingId', props.match.params.id);
            params.append('programId', bookingDetail.programId);
            params.append('camperId', camperId);
            params.append('file', e.target.files[0]);

            uploadGardianPdfService(params).then(res => {
                getBookingInfo();
            })

        
    }


    const handleFileChange = (e, camperId) => {
        if(e.target.files[0]){
            const data = new FormData();
            data.append('file', e.target.files[0]);
            uploadMedia(data).then((res) => {
                if(res.data.responseData.result.fileUrl){
                    let params = {
                        bookingId: props.match.params.id,
                        programId: props.match.params.programId,
                        camperId: camperId,
                        medicalDocument: res.data.responseData.result.fileUrl
                    }
    
                    updateBookingKeyService(params).then(res => {
                        if(res?.data?.statusCode){
                            getBookingInfo();
                        }
                    })
                }
            })
        }
        
    }




    const handlePrint = e => {
        setTimeout(() => window.print(), 0)
      };


    const handleBookingCancel = () => {
        let _campers = campers.filter(i => selectedCamperForCancelBooking.find(m => m === i.camperId)).map(v => ({programCost: v.programCost, camperId: v.camperId}));


        
        let params = {
            bookingId: props.match.params.id,
            programs: [
                {
                    programId: bookingDetail.programId,
                    campers: _campers
                }
            ]
        }
        setCancelBookingLoader(true);
        cancelProgramBookingByCampers(params).then(res => {
            setCancelBookingLoader(false);
            if(res?.data?.statusCode){
                history.push('/profile/bookings')
                notification.open({
                    message: 'Booking',
                    description:
                        'Booking cancelled',
                });
            }
        })
    }


    const handleCancelCamperSelect = id => {
        let temp = [...selectedCamperForCancelBooking]
        if(temp.includes(id)){
            temp = temp.filter(i => i !== id);
        }else{
            temp.push(id);
        }
        setSelectedCamperForCancelBooking(temp)
    }


    const checkCancelStatus = arr => {
        if(arr?.length){
            let _arr = arr.filter(i => i.status  === 1);
            if(_arr.length){
                return true;
            }
            else{
                return false
            }
        }
    }


    const getMedicalDocumentUrl = (data) => {
        if(data.length){
            let _res = data.filter(i => (i !== ''));
            return _res[0]
        }else{
            return '';
        }
      
    }
    const showProductInfo = (pp, productDetails = {}) => {
        pp['selectedColor'] = productDetails.color;
        pp['selectedSize'] = productDetails.sizes;
        pp['count'] = productDetails.count;
        setActiveProduct(pp)
        setIsViewProducts(true);
     }
     const handleCloseProductModal = () => {
        setIsViewProducts(false)
    }



    const getAgeGroupsOfBooking = (itemId, groups) => {
        if(itemId && groups.length){
            let _group = groups.find(i => i.camplistid === itemId);

            return (
                <div>
                    <div><b>Age Group: {_group.age}</b></div>
                    
                    <div className='age_g_days in_booking_detail'>
                        {_group?.days.map((dd, i) => (<div key={i}>
                            <span>{dd.date} From <b>{dd.starttime}</b> to <b>{dd.endtime}</b></span>
                        </div>))}
                    </div>
                </div>
            )

        }else {
            return ''
        }
    }

    useEffect(() => {
        if(bookingDetail){
            let filteredStartDates
            if(bookingDetail.byDates === 1){
                filteredStartDates = bookingDetail.additionalDates.filter(item => bookingDetail.selectedDates.includes(item._id)).map(item => item.startDate);
                setDisableDocsUpload(filteredStartDates.reduce( (last , i) => last || isLessThanEquals14Days(i), false))
            }else{
                setDisableDocsUpload(isLessThanEquals14Days(bookingDetail.startDate))
            }            
        }
    }, [bookingDetail])

    return(
        <div className="body_container program_page">
             <Modal
                    dialogClassName="h_auto"
                    show={isViewProducts}
                    animation={false}
                    size="xl"
                    centered>
                    <Modal.Body>
                    <div className="">
                        <span className="modal_close" onClick={handleCloseProductModal}><i className="icon-cross" /></span>
                    </div>
                    { activeProduct &&
                        <div className='row'>
                            <div className='col-lg-4 pb-5'>
                                { 
                                    activeProduct?.storeData[0]?.image?.length ? 
                                    <Slider {...settings}>
                                        {activeProduct?.storeData[0]?.image.map(img => (
                                                <img src={img} className="img-fluid pointer" key ={uuidv4()}/>
                                        ))}
                                    </Slider> : ''
                                }
                            </div>
                            <div className='col-lg-8'>
                            <div className='product_text'>
                                <h3 style={{color: activeProduct?.storeData[0]?.textColor || '#000'}}>{activeProduct?.storeData[0]?.title}</h3>
                                {activeProduct?.camperData?.firstName  && <p><b>Camper Name:</b> {activeProduct?.camperData?.firstName} {activeProduct?.camperData?.lastName}</p>}
                                {activeProduct?.selectedColor && <p><b>Color:</b> {activeProduct?.selectedColor}</p>}
                                {activeProduct?.selectedSize && <p><b>Size:</b> {activeProduct?.selectedSize} </p>}
                            </div>
                            { activeProduct?.storeData[0]?.subItems?.length > 0 && 
                                <>
                                <h4>Add-ons</h4> 
                                <div className="d-flex flex-column flex-sm-row flex-wrap align-content-stretch align-items-stretch">
                                { activeProduct?.storeData[0]?.subItems?.map((subItem, si) => 
                                    <div className='product_card p-3 mr-3' key= {uuidv4()}>
                                        {
                                            subItem?.image?.length && 
                                            <img src={subItem.image[0]} className="img-fluid" style={{ width: '120px', height: "120px" }} />

                                        }
                                        <div className='product_text'>
                                            <div style={{ width: '120px'}}>
                                                <h3 style={{color: subItem.textColor || '#000'}}>{subItem.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                
                                )
                                    }
            
                                </div>
                                </>
                            }
                        </div>
                    </div>
                    }
                </Modal.Body>
            </Modal>
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}

            <div className="container booking_detail">
               
                    <div className="qr_code_wrap show_on_print">
                        <h3 className="mb-4">My QR Code</h3>
                        {bookingDetail.qr && bookingDetail.qr.length ? bookingDetail.qr.map(item => (
                                    <div className="qr_img" key={item._id}>
                                        <img src={item.qrCode} className="img-fluid" alt=""/>
                                        <h5>{item.camperName}</h5>
                                        <h6 className="text-center mt-3">Group code: {item.groupCode ? item.groupCode : 'N/A'}</h6>
                                    </div>
                                )) : <div className="no_img"><LogoWhite /></div>
                        }
                    </div>

                <div className="d-flex justify-content-between program_head">
                    <div>
                    <h2><img src={bookingDetail.campLogo} alt=""/> {bookingDetail.campName} - {bookingDetail.programName}</h2>
                    </div>
                    <div className="text-center">
                        <div className="ratings">
                            <span>EZPro: </span>
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                        </div>
                        <small>({bookingDetail.ratingCount} reviews)</small>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-8 prog_main">

                        <div className="prog_media prog_media_desk hide_on_print">

                            {bookingDetail.programImages && bookingDetail.programImages.filter(ll => !ll.viewType).length ? 
                                <Slider {...settings2}>
                                    {bookingDetail.programImages.filter(i => !i.viewType).map(item => (
                                        <div className="prog_slide_img" key={item._id}>
                                            <img src={item.url || bookingDetail.programImage} className="img-fluid" alt="program"/>
                                        </div>
                                    ))}
                                </Slider> : bookingDetail.programImage ? <img src={bookingDetail.programImage} className="img-fluid" alt=""/> 
                                : 
                                <div className="no_img"><LogoWhite /></div>
                            }
                                
                            <i className={`cm_follow icon-plus ${isFollowed ? 'active' : ''}`} onClick={handleFollow} />

                            <div className="prog_action">
                                <i className={`icon-heart${isFavourite ? '-fill' : ''}`} onClick={handleFavourite} />
                                <Share pageUrl={`Booking=${props.match.params.id}`} />
                            </div>                      
                        </div>



                        <div className="prog_media prog_media_mob hide_on_print">

                            {bookingDetail.programImages && bookingDetail.programImages.filter(ll => ll.viewType).length ? 
                                <Slider {...settings2}>
                                    {bookingDetail.programImages.filter(i => i.viewType).map(item => (
                                        <div className="prog_slide_img" key={item._id}>
                                            <img src={item.url || bookingDetail.programImage} className="img-fluid" alt="program"/>
                                        </div>
                                    ))}
                                </Slider> : bookingDetail.programImage ? <img src={bookingDetail.programImage} className="img-fluid" alt="program"/> : 
                                <div className="no_img"><LogoWhite /></div>
                            }
                            
                            <i className={`cm_follow icon-plus ${isFollowed ? 'active' : ''}`} onClick={handleFollow} />

                            <div className="prog_action">
                                <i className={`icon-heart${isFavourite ? '-fill' : ''}`} onClick={handleFavourite} />
                                <Share pageUrl={`Booking=${props.match.params.id}`} />
                            </div>                      
                        </div>



                        <div className={`d-flex justify-content-${bookingDetail.byDates === 1 ? 'end' : 'between'} mt-3 mb-4 prog_d`}>
                            {
                                bookingDetail.byDates === 1 ? '' : <h3>{campDates2(bookingDetail.startDate, bookingDetail.endDate)}</h3>
                            }
                            

                            <div className="prog_price">
                                {bookingDetail.byDates === 1 ? (
                                    <h3>{getProgramPriceByDates(bookingDetail.additionalDates, bookingDetail.selectedDates)}</h3>
                                ) : (
                                    <h3>Price : <b>${bookingDetail.registrationFee?.toFixed(2)}</b></h3>
                                )}
                                
                            </div>
                            
                           
                        </div>




                        <h3>Booking Information</h3>

                        <div className="row mt-4">
                            <div className="col-md-6">
                                <p><b>Booking date: </b>{moment.utc(bookingDetail.bookingDate).format('DD MMMM, YYYY')}</p>

                                {
                                    bookingDetail.byDates === 1 ? (
                                        <p><b>Program dates: </b><br />{getSelectedDates(bookingDetail.additionalDates, bookingDetail.selectedDates)}</p>
                                           
                                    ) : (
                                        <p><b>Program dates: </b> {campDates(bookingDetail.startDate, bookingDetail.endDate)}</p>
                                    )
                                }
                                
                                



                                <p><b>Program Name: </b>{bookingDetail.programName}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Booking Number: </b>{bookingDetail.bookingNumber}</p>
                            </div>
                        </div>



                        <h3>Program Details</h3>

                        <div className="row mt-4">
                            <div className="col-md-6 cm_bor_right">
                            {bookingDetail.districtName ? <p><b>District Name: </b>{bookingDetail.districtName}</p> : ''}
                            <p><b>Programs: </b>{bookingDetail.programName}</p>
                                <p><b>Age Group: </b>{bookingDetail.ageGroup}</p>
                                <p><b>Sex: </b>{bookingDetail.gender === 1 ? 'Male' : ''}
                                                    {bookingDetail.gender === 2 ? 'Female' : ''}
                                                    {bookingDetail.gender === 3 ? 'Co-Ed' : ''}</p>
                                <p className="last_dot"><b>Grade: </b>{bookingDetail.grade && gradeArr2.filter(el => bookingDetail.grade.find(e => el.value === e )).sort((a, b) => a.count - b.count).map(item => <Fragment key={item.value}>{item.value}<span>,</span> </Fragment>)}</p>
                                <p className="last_dot"><b>Meals Provided: </b>{bookingDetail.meals && bookingDetail.meals.length ? bookingDetail.meals.map(item => <Fragment key={item}>{item ? item : 'None'}<span>,</span> </Fragment>) : 'None'}</p>
                                <p><b>Check in Time: </b>{bookingDetail.checkInTime}</p>
                                <p><b>Check out Time: </b>{bookingDetail.checkOutTime}</p>
                            </div>
                            <div className="col-md-6">
                                <p><b>Type: </b>{bookingDetail.programType ? getProgramType(bookingDetail.programType) : 'N/A'}</p>
                                <p><b>Aftercare Available: </b>{bookingDetail.afterCare ? 'Yes' : 'No'}</p>
                                <p><b>Experience: </b>{bookingDetail.expLevel ? getExperience(bookingDetail.expLevel) : 'N/A'}</p>
                                <p><b>Travel: </b>{bookingDetail.travel === 1 ? '' : 'Not'} Included</p>
                                <p><b>T-Shirt Included: </b>{bookingDetail.tshirt === 1 ? 'Yes' : 'No'}</p>
                                <p><b>Program Code: </b>{bookingDetail.programCode}</p>
                            </div>
                        </div>



                        <div className="sm_section mt-5">
                            <h3>Instruction, Equipment & Facility</h3>
                            <ul>
                                <li>Instruction :<p className="pl-4">{getHtml(bookingDetail.programInstruction || 'N/A')}</p></li>
                                <li>Equipment Needed : <p className="pl-4">{bookingDetail.equipment || 'N/A'}</p></li>
                                <li>Facility : <p className="pl-4">{bookingDetail.facility ? getFacility(bookingDetail.facility) : 'N/A'}</p></li>
                            </ul>
                        </div>

                        <div className="sm_section">
                            <h3 className="mb-3">Important Notes</h3>
                            <p>{bookingDetail.impNotes ? getHtml(bookingDetail.impNotes) : 'N/A'}</p>
                        </div>


                        {bookingDetail.travel === 1 ?
                            <div className="sm_section">
                                <h3 className="mb-3">Travel Info</h3>
                                <p>{bookingDetail.travelInfo ? getHtml(bookingDetail.travelInfo) : 'N/A'}</p>
                            </div>:''
                        }

                        {campers.length ? campers[0].additionalExperience.length ? <div className="sm_section">
                        <h3 className="mb-3">Additional Experience</h3>
                        {
                            campers.map(item => <div className="">{item.additionalExperience.map(addit => <div className="cm_addit">
                                    <p><b>Title</b></p>
                                    <p>{getHtml(addit.title)}</p>
                                    <p><b>Number Purchased:</b> {addit.numberOfBooking}</p>
                                    <p><b>price:</b> ${parseInt(addit.price).toFixed(2)}</p>
                                </div>)}</div>)
                        }
                        </div> : '' : ''}


                        <div className='row'>

                            {bookingDetail?.purchaseData?.length ? <div className='col-12'>
                                <h3>Products</h3>
                                {/* <h6>Total Products Amount ${bookingDetail?.purchaseItemPrice.toFixed(2)}</h6> */}
                            </div> : ''}

                      

                        {
                            bookingDetail?.purchaseData?.length ? bookingDetail?.purchaseData.map((pp, index)=> (
                                <>
                                {pp?.storeData && 
                                    <div className='col-lg-6 col-md-6' key={uuidv4()}>
                                        <div className='product_card'>

                                            {
                                                pp?.storeData[0]?.image?.length ? <Slider {...settings}>
                                                                        {pp?.storeData[0]?.image.map(img => (
                                                                                <img src={img} className="img-fluid pointer" alt=""/>
                                                                        ))}
                                                                    </Slider> : ''
                                            }

                                    
                                            <div className='product_text'>
                                                <h3 style={{color: pp?.storeData[0]?.textColor || '#000'}}>{pp?.storeData[0]?.title}</h3>
                                                {pp?.camperData?.firstName &&  <p><b>Camper Name:</b> {pp?.camperData?.firstName} {pp?.camperData?.lastName}</p>}
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    {pp?.totalPrice &&  <p><b>Total Price:</b> {pp?.totalPrice }</p>}
                                                    {bookingDetail.purchaseInfo[index]?.payment?.status === 3 ? <span className='p_cat_text' style={{background:'red'}}>Failed Payment</span> : ''}
                                                </div>
                                                <button  type="button" class="btn btn-light" onClick={() => showProductInfo(pp, bookingDetail.purchaseInfo[index].items[0])}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                    </svg>
                                                    <span className='ml-2'><i>Product info</i></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </>
                            )) : ''
                        }

</div>

                    </div>


                    <div className="col-lg-4">
                       
                        <h3 className="hide_on_print">My QR Code</h3>
                        <div className="qr_code_wrap hide_on_print">
                        {bookingDetail.qr && bookingDetail.qr.length ? <Slider {...settings}>
                                {bookingDetail.qr.map(item => (
                                    <div className="qr_img" key={item._id}>
                                        <img src={item.qrCode} className="img-fluid" alt=""/>
                                        <h5>{item.camperName}</h5>
                                        <h6 className="text-center mt-3">Group code: {item.groupCode ? item.groupCode : 'N/A'}</h6>
                                    </div>
                                ))}
                            </Slider> : <div className="no_img"><LogoWhite /></div>
                        }
                        </div>


                        <Modal
                            show={activeForm.camperId}
                            centered
                            size='xl'
                            dialogClassName="remove_header_line">

                            <Modal.Header>
                            <span className="modal_close" onClick={() => setActiveForm({})}><i className="icon-cross" /></span>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='embed_div'>
                                    <PdfViewer fileUrl={activeForm.url}/>
                                </div>

                                

                            
                                <div className="d-flex mt-3">
                                    <button onClick={() => setActiveForm({})} className="btn btn-dark w-100">Cancel</button>
                                    {
                                        activeForm.viewType ? '' : <button onClick={updateBookingKey} className="btn btn-primary w-100 ml-3">I Agree</button>
                                    }
                                    
                                </div>

                            </Modal.Body>
                        </Modal>



                        <div>
                            <Link to={`/online-store`}>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col xs={12} md={8} lg={12}>
                                        <img 
                                            src={buttonGif} 
                                            alt="Loading..." 
                                            className="img-fluid" 
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            </Link>
                        </div>


                        <ul className="booking_users booking_users2">
                            {
                                campers.map((item, inde) => (
                                        <li key={inde}>
                                            {disableDocsUpload && <p style ={{fontSize:"0.85rem"}} className="text-danger mb-3">Note: Online Form Upload is No Longer Available, Please Bring your Forms in Person to Camp.</p>}
                                            <h6>{item.camperName}</h6>

                                            {
                                                item?.isCommuter ? '' : (
                                                    <div className='get_forms'>
                                                       <p>Key Deposit</p>
                                                        {item.keyDeposit ? 
                                                            <span 
                                                            onClick={() => setActiveForm({viewType: true, camperId: item.camperId, type: 'keyDeposit', url: require('../../assets/pdf/key-deposit.pdf')})} 
                                                            className="updated_icon"><img src={require('../../assets/img/check_icon.png')} 
                                                            alt=""/> Updated</span>  
                                                            : 
                                                            disableDocsUpload ?'' :
                                                            <span 
                                                                className={`btn btn-secondary btn-sm`}
                                                                onClick={() => setActiveForm({camperId: item.camperId, type: 'keyDeposit', url: require('../../assets/pdf/key-deposit.pdf')})}
                                                                >View</span>}
                                                    </div>
                                                )
                                            }

                                            <div className='get_forms'>
                                                <p>Concussion Waiver Document </p>
                                                {item.concussion ? 
                                                <span 
                                                    onClick={() => setActiveForm({viewType: true, camperId: item.camperId, type: 'concussion', url: require('../../assets/pdf/concussion-protocol.pdf')})} 
                                                    className="updated_icon ">
                                                    <img src={require('../../assets/img/check_icon.png')} alt=""/>
                                                    Updated
                                                 </span>  
                                                 : 
                                                 disableDocsUpload ?'' :
                                                 <span 
                                                    onClick={() => setActiveForm({camperId: item.camperId, type: 'concussion', url: require('../../assets/pdf/concussion-protocol.pdf')})} 
                                                    className={`btn btn-secondary btn-sm`}>
                                                    View
                                                </span>}
                                            </div>

                                            <div className='get_forms'>
                                                <p>Attach medical clearance form & physical </p>
                                                {getMedicalDocumentUrl(item?.medicalDocument) ? 
                                                <span 
                                                    onClick={() => setActiveForm({viewType: true, camperId: item.camperId, type: 'concussion', url: getMedicalDocumentUrl(item?.medicalDocument)})} className="updated_icon"><img src={require('../../assets/img/check_icon.png')} alt=""/> Updated</span>  
                                                : 
                                                disableDocsUpload ?'' :
                                                    <span  className={"btn btn-outline-secondary btn-sm"} style={{position: 'relative'}}>
                                                    Attach PDF 
                                                   <input type='File' onChange={e => handleFileChange(e, item.camperId)} className='mm_file' />
                                                    </span>
                                                    }
                                            </div>
                                            

                                                {getAgeGroupsOfBooking(item.camplistid, bookingDetail.agegroups)}


                                        </li>
                                    )
                                )
                            }
                        </ul>

                        <div className='waiver_disclaimer'>
                            <p>Waiver Disclaimer</p>
                            {bookingDetail.isDisclaimer ? 
                                <span onClick={() => setActiveForm({viewType: true, camperId: 'isDisclaimer', type: 'isDisclaimer', url: require('../../assets/pdf/Waiver-disc.pdf')})} className="updated_icon"><img src={require('../../assets/img/check_icon.png')} alt=""/> Updated</span>  
                                : 
                                disableDocsUpload ? '' :
                                <span className="btn btn-outline-secondary btn-sm" onClick={() => setActiveForm({camperId: 'isDisclaimer', type: 'isDisclaimer', url: require('../../assets/pdf/Waiver-disc.pdf')})}>View </span>
                                
                                }
                        </div>
                        {
                            setting.api.sportInternationalCamp === bookingDetail.campId ? <div className='d-flex justify-content-center mb-5'>
                            <a className='btn btn-primary' download href={require('../../assets/pdf/Medical-Clearance-Form.docx')}>Download Medical Clearance Form</a>
                        </div> : ''
                        }


                        {bookingDetail.isOnline ? 
                            (<h2 className="cm_on_line">Online Program</h2>)
                            :
                            (<div className="camp_detail_map">
                                { bookingDetail.geoLocation ? <MapComponent geoLocation={bookingDetail.geoLocation} {...props} /> : ''}
                                <div className="camp_detail_address">
                                    {bookingDetail.city ? <p className="m-0">{`${bookingDetail.addressLine1} ${bookingDetail.city} ${bookingDetail.state}`}</p> : ''}
                                </div>
                            </div>)}
                        {
                            bookingDetail.packingList ? <div className="text-center hide_on_print">
                            <a href={bookingDetail.packingList} download className="btn btn-primary rounded-0 pl-4 pr-4"><i className="icon-doc-check" /> Download Packing List</a>
                        </div> : ''
                        }

                        
                        <div className="text-center mt-3 hide_on_print">
                            <button onClick={handlePrint} className="btn btn-sm btn-primary rounded-0 pl-4 pr-4"><i className="icon-print" /> Print</button>
                        </div>

                        
                        
                        

                        <h3 className="mt-4">Schedule</h3>
                        <div className="daily_schedule mb-4">
                            <ul>{bookingDetail.schedule && bookingDetail.schedule.length ? bookingDetail.schedule.map(item => (
                                <li key={item._id}><b>{item.time}</b> {item.activity}</li> 
                                )) : <li>Not updated</li>}
                            </ul>
                            
                        </div>

                        <h3>Price Details</h3>
                        <div className="p_detail_wrap">
                            
                            <ul className="booking_users">
                                {campers.map((item, inde) => <li key={inde}>
                                    {item.status === 2 ? <span className='book_status'>Cancelled</span> : ''}
                                    {item.status === 3 ? <span className='book_status'>Refunded</span> : ''}

                                    {pdfLoader ? <div className="upload_loader"><UploadLoader /></div> : <OverlayTrigger
                                        key={item.camperId}
                                        delay={{ show: 10, hide: 200 }}
                                        overlay={<Tooltip className="dark_tooltip">Upload PDF</Tooltip>}>
                                        
                                        <div className="cm_upload_pdf"><i className="icon-upload" /><input type="file" value="" onChange={e => handleFileUpload(e, item.camperId)} accept="application/pdf" /></div>
                                    
                                    </OverlayTrigger>}

                                    

                                    <h6>{item.camperName}</h6>
                                    {item.gardianPdf ? <div className="gardianPdf"><a target="_blank" href={item.gardianPdf} rel="noopener noreferrer"><PDFIcon /> PDF Uploaded</a></div> : ''}



                                    <div className="d-flex justify-content-between">
                                        <span>Program Cost: </span>
                                        {bookingDetail.byDates === 1 ? <b>${getProgramPriceByDates2(bookingDetail.additionalDates, bookingDetail.selectedDates)}</b> : <b>${item.programCost?.toFixed(2)}</b>}
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <span>Convenience Fee: </span>
                                        <b>${bookingDetail.convenienceFee.toFixed(2)}</b>
                                    </div>
                                    {
                                        item.additionalExperience.length ? (
                                            <div className="d-flex justify-content-between">
                                                <span>Additional Experience: </span>
                                                <b>${(item.additionalExperience[0].price * item.additionalExperience.length).toFixed(2)}</b>
                                            </div>
                                        ) : ''
                                    }
                                    
                                    {item.discountPrice ? (
                                        <div className="d-flex justify-content-between">
                                            <span>Discount: </span>
                                            <b>- ${item.discountPrice?.toFixed(2)}</b>
                                        </div> 
                                    ): ''}
                                    
                                </li>)}
                            </ul>

                
                           
                            

                            <div className="d-flex justify-content-between gt_total">
                                <span>Grand Total : </span>
                                {bookingDetail.byDates === 1 ? <b>${getProgramPriceByDates2(bookingDetail.additionalDates, bookingDetail.selectedDates)}</b> : <b>${bookingDetail?.registrationFee?.toFixed(2)}</b>}
                            </div>

                            {
                                bookingDetail.insurance ? <div className="d-flex justify-content-between gt_total">
                                <span>Insurance : </span>
                               <b>${bookingDetail?.insurance?.toFixed(2)}</b>
                            </div> : ''
                            }
                            {checkCancelStatus(bookingDetail?.campers) ?  <div className="text-center hide_on_print">
                                <button className="btn btn-primary rounded-0" onClick={() => setCancelModal(true)}>Cancel Booking</button>
                                </div> : '' }
                            
                        </div>



                    </div>
                </div>


                {
                     bookingDetail?.isReview === 2 ? '' : todayDate < bookingDetail.startDate ? '' : <div className="review_sec">
                     <img src={require('../../assets/img/logo.svg')} alt=""/>
                     <h6>Rate your camp experience</h6>
 
                     {
                         reviewSuccess ? 
                         <p>Review submitted successfully</p>
                         :
                     <>                
                     <p>If you  have specific feedback, please let us know below</p>
                     <Rate value={rating} onChange={e => setRating(e)} />
 
                     <textarea 
                         className={`form-control ${reviewError ? 'is-invalid' : ''}`}
                         placeholder="Message"
                         value={reviewText}
                         onChange={e => (setReviewText(e.target.value), setReviewError(false)) } />
 
                     <div className="text-center cm_round_btn mt-4">
                         <button onClick={() => submitReview(bookingDetail.campId)} className="btn btn-primary pl-4 pr-4">Submit</button>
                     </div>
                     </>
                     }
                 </div> 
                }

                




            </div>

            

            <Modal
                show={cancelModal}
                centered
                size='lg'
                dialogClassName="remove_header_line">

                <Modal.Header>
                    <h3>Cancel Booking</h3>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-5 cancel_policy">
                        

                        <p>If a Non-Refundable Registration Fee is listed, the below terms are not applicable to this portion of the fees but are applicable to all other fees.  The 24hr refund policy applies to all fees and all purchases, at all times.<br />Cancellation terms vary by program please click the "yellow" button next to other programs to check their terms as well, if you are purchasing multiple programs provided by different providers.</p>

                        {
                            refundAvailable === 1 ? (
                                <Fragment>
                                    <h6>{refundType === 2 ? 'Original Form of Payment' : 'Program Voucher'}</h6>
                                    <ul>
                                        {refundOptions.map(policy => <li>{`For ${policy.days}% refund, booking must be cancelled ${policy.percent} days prior to program start date.`}</li>)}
                                    </ul>
                                </Fragment>
                            ) : <h6>No Refund Available</h6>
                        }

                        <hr />

                        <h5 className="mb-4">Cancellation by <span className="color_red">{campName}</span></h5>
                        {
                            cancellationAvailable === 1 ? (
                                <Fragment>
                                    <h6>{cancellationType === 2 ? 'Original Form of Payment' : 'Program Voucher'}</h6>
                                    <ul>
                                        <li>{`Refund amount will be ${cancellationValue}%  In the case of a cancellation prompted by low enrollment or other extenuating circumstances.`}</li>
                                    </ul>
                                </Fragment>
                            ) : <h6>No Refund Available</h6>
                        }


                        <hr />


                        <p><b>Please select participent which you want remove from booking</b></p>
                        <ul className="booking_users pl-0">
                            {campers.map(item => <li key={item.camperId} className={`by_cancel_camper_list ${item.status === 2 || item.status === 3 ? 'd-none' : ''}`}>

                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                            name='participents'
                                            checked={selectedCamperForCancelBooking.includes(item.camperId)}
                                            onChange={() => handleCancelCamperSelect(item.camperId)}
                                            type="checkbox" />
                                        <span className="check_box"><span></span></span>
                                    </div>

                                <h6>{item.camperName}</h6>
                                {item.gardianPdf ? <div className="gardianPdf"><a target="_blank" href={item.gardianPdf} rel="noopener noreferrer"><PDFIcon /> PDF Uploaded</a></div> : ''}

                                <div className="d-flex justify-content-between">
                                    <span>Program Cost: </span>
                                    {bookingDetail.byDates === 1 ? <b>${getProgramPriceByDates2(bookingDetail.additionalDates, bookingDetail.selectedDates)}</b> : <b>${item.programCost?.toFixed(2)}</b>}
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>Convenience Fee: </span>
                                    <b>${bookingDetail.convenienceFee.toFixed(2)}</b>
                                </div>
                                {
                                    item.additionalExperience.length ? (
                                        <div className="d-flex justify-content-between">
                                            <span>Additional Experience: </span>
                                            <b>${(item.additionalExperience[0].price * item.additionalExperience.length).toFixed(2)}</b>
                                        </div>
                                    ) : ''
                                }
                                
                                {item.discountPrice ? (
                                    <div className="d-flex justify-content-between">
                                        <span>Discount: </span>
                                        <b>- ${item.discountPrice?.toFixed(2)}</b>
                                    </div> 
                                ): ''}
                                
                            </li>)}
                        </ul>


                        {
                            selectedCamperForCancelBooking.length ? <div className="mt-4 mb-4 d-flex">
                            <span className="btn btn-dark btn-block mr-2" onClick={closeModal}>No</span>
                            <span className="btn btn-primary btn-block ml-2" onClick={() => cancelBookingLoader ? null : handleBookingCancel()}>Yes</span>
                        </div> : ''
                        }




                    </div>

                </Modal.Body>
            </Modal>
            
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
      userProfile
    }
}
  
export default connect(mapStateToProps)(ViewBooking);