import React, {useState, useEffect, lazy, Suspense} from 'react';
import { connect } from 'react-redux';
import {  Col, Container, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import {
    directorbookinglist,
    directorCamperlist,
    deleteBookingfolder,
    counselordetail
} from '../../services/directorServices';
import { toggleAddFolderModal } from '../../store/ui';
import BookingsList from './BookingsList';
import FoldersList from './FoldersList';
import CampCardSkeleton from './CampCardSkeleton';

const AddFolderModal = lazy(() => import("./BookingModals/AddFolderModal"));
const  FolderModal = lazy(() => import('./BookingModals/FolderModal'));
const ConfirmModal = lazy(() => import('../modals/ConfirmModal'));

const  limit = 5 ;

const Bookings = props => {
    const [bookings, setBookings]                     = useState([]);
    const [folders, setFolders]                       = useState([]);
    const [loading, setLoading]                       = useState(true);
    const [addFoder, setAddFolder]                    = useState(false);
    const [folderPopup, setFolderPopup]               = useState(false);    
    const [folderName, setFoldername]                 = useState('');
    const [folderNameErr, setFoldernameErr]           = useState('');

    const [deleteFolder, setDeleteFolder]             = useState(false);
    const [deleteFolderId, setDeleteFolderId]         = useState();
    const [folderalreadyExit, setFolderAlreadyExit]   = useState(false);
    const [search, setSearch]                         = useState('');
    const [bookingType, setBookingType]               = useState(2);
    const [permissions, setPermissions]               = useState({});
    const [page, setPage]                             = useState(1);
    const [totalCount, setTotalCount]                 = useState(0);


    useEffect(() => {
        if(props.UserType === 4){
            counselordetail(`/?counselorId=${props._id}`).then((res) => {
                if(res.data.statusCode === 1){
                    setPermissions(res.data.responseData.result.permissions || {}) 
                }
            })
        }
    }, [])


    useEffect(() => {
        (search) && setBookings([])
        const _setTimeout = setTimeout(getDirectorbookinglist,100)
        return () => clearTimeout(_setTimeout)
    }, [bookingType, page, search])

    


    const getDirectorbookinglist = () => {
        setLoading(true);
        let params = `?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        if(page){
            params =  params + `&page=${page}`
        }
        if(limit){
            params += `&limit=${limit}`
        }
    
        directorbookinglist(params).then(res => {
            if(res?.data?.statusCode){
                setTotalCount(res.data.responseData.result.count)
                if(page > 1) { 
                    setBookings (prev => [...prev , ...res.data.responseData.result.programs])
                }else{
                setBookings(res.data.responseData.result.programs)
                setFolders(res.data.responseData.result.folder)
                }
               
            }
            setTimeout(() => {
                setLoading(false);
            }, 10)
        })
    }
    

    const handelCreateFolder = () => {
        if(folderName === '' || folderName.trim() === ''){
            setFoldernameErr('Folder name is required');
        }else{
            setFolderPopup(false);
            setAddFolder(true)
        }
    }
    
    
   const addfolderCallback = (status) => {
        if(status){
            setAddFolder(false); 
            setFoldername(''); 
            notification.open({
                description:
                  'Folder created successfully ',
              });
            getDirectorbookinglist();
        }
        else{
            setFolderAlreadyExit(true)
        }
   } 



    const deleteFolderAction = () => {
        let params = `/?folderId=${deleteFolderId}`;
        deleteBookingfolder(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                setDeleteFolder(false);
                getDirectorbookinglist();
                notification.open({
                    description:
                      'Folder Deleted Successfully',
                  });
            }
        })   
    }
    
/** *
    const applyfilter = () => {

  
        setIsHideDropdown(false);

        setLoading(true);
        let campCode = props.campCode
        //let params = `/?campCode=${campCode}`
        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        if(agegroup!== undefined){ params += `&ageGroup=${agegroup}` }
        if(gender!==undefined){ params += `&gender=${gender}` }
        getDirectorbookinglist();
    }
/** */
/** *
    const clearfilter = () => {
        setIsHideDropdown(false);
        setAgeGroup('');
        setGender('');
        setLoading(true);
        let campCode = props.campCode
       // let params = `/?campCode=${campCode}`
        let params = `/?campCode=${props.campCode}&programType=${bookingType}&search=${search}`
        directorbookinglist(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                setBookings(res.data.responseData.result.programs)
                setFolders(res.data.responseData.result.folder)
                setLoading(false);
            }
            else
            {
                setLoading(false);

            }
        })  
    }
/** */
    const cancleDeleteAction = () => {
        setDeleteFolder(false)
        setDeleteFolderId('')
    }

    const updatePageItems = () => {
        setPage(page + 1);
    }
    const handleBookingType = (type) => {
        setPage(1)
        setBookingType(type)
    }


    return(
        <div className="body_container pb-4 director_wrapper cm_over_auto">
                <Suspense fallback={<div>Loading Modal...</div>}>
                    <FolderModal
                        folderPopup={folderPopup}
                        setFolderPopup={setFolderPopup}
                        folderName={folderName}
                        setFoldername={setFoldername}
                        setFoldernameErr={setFoldernameErr}
                        folderNameErr={folderNameErr}
                        handelCreateFolder={handelCreateFolder}
                    />
                </Suspense>
            
                <Suspense fallback={<div>Loading Modal...</div>}>
                    <ConfirmModal
                        show={deleteFolder} 
                        hideModal={cancleDeleteAction} 
                        msg={'Are you sure you want to delete this folder'} 
                        action={deleteFolderAction}
                    />
                </Suspense>
                <Suspense fallback={<div>Loading Modal...</div>}>
                    <AddFolderModal
                        show={addFoder}
                        hideModal={setAddFolder}
                        setAddFolder={setAddFolder}
                        setFoldername={setFoldername}
                        folderalreadyExit={folderalreadyExit}
                        folderName={folderName}
                        addfolderCallback={addfolderCallback}
                    />
                </Suspense>


            
            <Container>
                <Row className="d-flex align-items-center justify-content-between page_card_header">
                    <Col><h4>Bookings</h4></Col>
                    <Col className="btn_group">
                        <button className="btn fix_btn btn-outline-primary m-1" onClick={() => window.print()}><i className="icon-print" /> Print</button>
                        <button className="btn btn-primary m-1" onClick={() => setFolderPopup(true)}><i className="icon-folder-2" /> Add Folder</button>
                        {props.UserType === 4 && !permissions.createBooking ? '' : <Link to="/booking/create" className="btn fix_btn btn-outline-primary m-1"><i className="icon-plus" /> Create Booking</Link>}
                        
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="d-flex align-items-center justify-content-between page_card_header cm_border_0">
                            <Col> <h4>Bookings Info</h4></Col>
                            <Col>
                                <button onClick={() => handleBookingType(2)} className={`m-1 btn btn-outline-dark ${bookingType === 2 ? 'active' : ''}`}>Upcoming</button>
                                <button onClick={() => handleBookingType(1)} className={`m-1 btn btn-outline-dark ${bookingType === 1 ? 'active' : ''}`}>Past</button>
                            </Col>
                        </Row>

                        <Row className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                value={search}
                                onChange={e => {setSearch(e.target.value); setPage(1)}}
                                placeholder="Search" />
                        </Row>
                        <Row> 
                            <Col 
                                style={{height:"75vh"}}              
                                className='invisible-scrollbar'
                            >{loading ? Array(5).fill(null).map((_, index) => <CampCardSkeleton />) 
                                :
                            <>
                                {folders.length > 0 && <FoldersList folders={folders} setDeleteFolder={setDeleteFolder} setDeleteFolderId={setDeleteFolderId}/>}
                                <BookingsList bookings={bookings} permissions={permissions} />
                            </>                   
                            }
                            </Col>
                        </Row>

                    </Col>
                </Row> 
                <Row className="d-flex justify-content-end mt-5">
                    {
                        (totalCount > page *limit) &&
                        <button
                            className='btn btn-primary'
                            onClick={updatePageItems}
                        >Load More...</button> 
                    }
                </Row>           
            </Container>

           

        </div>
    )
} 



/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {campCode, _id, UserType } = state.user.userProfile;
    return {
        state,
        campCode,
        UserType,
        _id
    };
}

const mapDispatchToProps = dispatch => ({
    toggleAddFolderModal: params => dispatch(toggleAddFolderModal(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Bookings);