import React from 'react'
import InfoModal from '../common/Modals/InfoModal';
const getCardTypeName = (type) => {
    const typeMap = {
        'american express': 'AmericanExpress',
        'visa electron': 'Visa',
        'visa': 'Visa',
        'mastercard': 'MasterCard',
        'discover': 'Discover',
        'china_unionpay': 'Unionpay',
        'diners club': 'DinersClub',
        'dinersclub': 'DinersClub'
    };

    return typeMap[type.toLowerCase()] || 'Visa';
}

const CreditCard = ({item, customerPaymentProfileId, setCustomerPaymentProfileId, setCustomerPaymentProfileIdErr, 
    handlePrimaryCard, handleDeleteCard}) => {
  return (
    <div className='d-flex justify-content-between'>
     <div style={{ display: 'flex' ,alignItems: 'center'}}>
        <div className="check_box_wrapper cm_dark_check pt-1">
            <input
                checked={customerPaymentProfileId === item.card.token}
                onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdErr(''))}
                type="radio"
                name="gender"
                value="male" />
            <span className="check_box"><span></span></span>
        </div>
        <div className="p_card_img ml-2">
            <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} alt=""/>
        </div>
        <span className='primary-card-text'>********{item.card.acct_last_four}</span>                                                  
        </div>
        <div className='primary-card-content'>
        { !(item.card?.primary) ?
            <>
            
                <div className="check_box_wrapper cm_dark_check">
                    <input
                        checked={item?.card?.metadata?.primary}
                        onClick={item?.card?.metadata?.primary ? "":() => handlePrimaryCard(item.card.token)}
                        type="radio"
                    />
                    <span className="check_box"><span></span></span>
                </div>
                <span className='primary-card-text'>set as primary</span> 
            </>
            :
            <span className = {`primary-card-text btn-primary`}>Primary Card</span>
         
        }
        <div className = ""><i onClick={() => handleDeleteCard(item.card.token)} className="icon-delete" style={ {fontSize: "24px"}}/></div>
    </div>
      
    </div>
  )
}

export default CreditCard
